import React from "react";
import EmailButton from "components/Social/RoundButtons/EmailButton";
import TwitterButton from "components/Social/RoundButtons/TwitterButton";
import DiscordButton from "components/Social/RoundButtons/DiscordButton";
import { Stack } from "@mui/material";

const FooterSocials = ({ gap = 1 }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={gap}
    >
      <TwitterButton width={50} height={17} padding={2} />
      <DiscordButton width={50} height={21} padding={2} />
      <EmailButton width={50} height={17} padding={2} />
    </Stack>
  );
};

export default FooterSocials;
