import { SEO_DESCRIPTION, TRIBALLY_TITLE } from "config";
import React from "react";
import Helmet from "react-helmet";

const SEO = (props) => {
  const title = TRIBALLY_TITLE;
  const description = SEO_DESCRIPTION;
  const url = "https://about.tribally.games";
  const siteName = TRIBALLY_TITLE;

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="Tribally, Tribally Games, Gaming with Benefits, $TRIBAL, Social-Fi Meets Game-Fi, Web3 Gaming, Web3 User Acquisition, User Acquisition in Web3, User Retention in Web3, Web3 Player Engagement, Web3 Growth Marketing"
      />
      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://tribally.games/images/social/tribally.jpg"
      />
      <meta property="og:site_name" content={siteName} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@TriballyGames" />
      <meta
        name="twitter:image"
        content="https://tribally.games/images/social/tribally.jpg"
      />
      <meta name="twitter:creator" content="@TriballyGames" />
    </Helmet>
  );
};

export default SEO;
