export const advisorLogos = [
  {
    id: 1,
    src: "/images/partners/AdvisorLogos/activision.png",
    alt: "Activision Logo",
    title: "Activision Logo",
    width: 110,
    height: "auto",
  },
  {
    id: 2,
    src: "/images/partners/AdvisorLogos/electronic-arts.png",
    alt: "Electronic Arts Logo",
    title: "Electronic Arts Logo",
    width: 55,
    height: "auto",
  },
  {
    id: 3,
    src: "/images/partners/mmg-logo.png",
    alt: "Method Meta Guild Logo",
    title: "Method Meta Guild Logo",
    width: 55,
    height: "auto",
  },
  {
    id: 4,
    src: "/images/partners/AdvisorLogos/epic-games.png",
    alt: "Epic Games",
    title: "Epic Games",
    width: 55,
    height: "auto",
  },
  {
    id: 5,
    src: "/images/partners/AdvisorLogos/mediatonic.png",
    alt: "Mediatonic",
    title: "Mediatonic",
    width: 85,
    height: "auto",
  },
  {
    id: 6,
    src: "/images/partners/AdvisorLogos/outright-games.png",
    alt: "Outright Games Logo",
    title: "Outright Games Logo",
    width: 105,
    height: "auto",
  },
  {
    id: 7,
    src: "/images/partners/AdvisorLogos/ethereum.png",
    alt: "Ethereum Foundation Logo",
    title: "Ethereum Foundation Logo",
    width: 90,
    height: "auto",
  },
];

export const teamLogos = [
  {
    id: 1,
    src: "/images/partners/TeamLogos/bank-of-america.png",
    alt: "Bank Of America Logo",
    title: "Bank Of America Logo",
    width: 120,
    height: "auto",
  },
  {
    id: 2,
    src: "/images/partners/TeamLogos/dell.png",
    alt: "Dell Logo",
    title: "Dell Logo",
    width: 55,
    height: "auto",
  },
  {
    id: 3,
    src: "/images/partners/TeamLogos/hamilton.png",
    alt: "Hamilton Insurance Group Logo",
    title: "Hamilton Insurance Group Logo",
    width: 95,
    height: "auto",
  },
  {
    id: 4,
    src: "/images/partners/TeamLogos/kpmg.png",
    alt: "KPMG Logo",
    title: "KPMG Logo",
    width: 75,
    height: "auto",
  },
  {
    id: 5,
    src: "/images/partners/TeamLogos/linde.png",
    alt: "Linde Logo",
    title: "Linde Logo",
    width: 80,
    height: "auto",
  },
  {
    id: 6,
    src: "/images/partners/TeamLogos/mars.png",
    alt: "Mars Logo",
    title: "Mars Logo",
    width: 100,
    height: "auto",
  },
  {
    id: 7,
    src: "/images/partners/TeamLogos/microsoft.png",
    alt: "Microsoft Logo",
    title: "Microsoft Logo",
    width: 95,
    height: "auto",
  },
];
