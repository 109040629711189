import { Stack } from "@mui/material";
import { creators } from "components/Team/data/foundingCreators";
import FoundingCreator from "components/Team/molecules/FoundingCreator";
const Creators = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={6}
      sx={{
        display: "flex",
        maxWidth: 996,
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      <FoundingCreator person={creators.cloudwhite} />
      <FoundingCreator person={creators.chief} />
      <FoundingCreator person={creators.artic} />
      <FoundingCreator person={creators.wiceTroc1ty} />
      <FoundingCreator person={creators.stark} />
      <FoundingCreator person={creators.creatoroftime} />
      <FoundingCreator person={creators.quinn} />
      <FoundingCreator person={creators.zimmahtv} />
    </Stack>
  );
};

export default Creators;
