import { Link, Stack } from "@mui/material";
import React from "react";
import { TRIBALLY_TWITTER } from "../../../config";
import TwitterX from "components/Social/svgIcons/TwitterX";

const TwitterButton = ({ color = "#fff", height, padding = 5 }) => {
  return (
    <Link href={TRIBALLY_TWITTER} target="_blank" title="Tribally Twitter">
      <Stack
        padding={padding}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundImage: `url(/images/about-us/socials-button.png)`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          color,
          svg: {
            height: height,
            width: "auto",
          },
          "&:hover": {
            transform: "scale(1.04)",
          },
        }}
      >
        <TwitterX height={height} />
      </Stack>
    </Link>
  );
};

export default TwitterButton;
