import React from "react";
import { Stack, Button, useTheme } from "@mui/material";
import { IoPlay } from "react-icons/io5";
import BannerImage from "../molecules/BannerImage";
import { TRIBALLY_URL } from "config";
import { colors } from "theme/colors";

const TaglineBanner = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        width: "100%",
        marginTop: -15,
      }}
    >
      <Stack
        sx={{
          width: "100%",
          backgroundImage: "url(/images/backgrounds/tagline-banner.jpg)",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: "20px",
        }}
      >
        <Stack
          sx={{
            paddingTop: 4,
            paddingBottom: 10,
            paddingLeft: 2,
            paddingRight: 2,
            maxWidth: 1152,
            alignItems: "center",
            margin: "0 auto",
            width: "100%",
            position: "relative",
            [theme.breakpoints.down("sm")]: {
              paddingBottom: 4,
            },
          }}
        >
          <img
            src="/images/bet-on-gaming-horizontal.png"
            alt="Bet On Gaming"
            style={{
              width: "100%",
              maxWidth: 800,
              margin: "0 auto",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            justifyContent="center"
            sx={{
              [theme.breakpoints.down("md")]: {
                mt: 2,
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <Button
              endIcon={<IoPlay />}
              variant="contained"
              size="large"
              href={TRIBALLY_URL}
              sx={{
                maxWidth: 200,
                width: "100%",
                background: "#fff",
                color: colors.black,
                boxShadow: "0 0 10px rgba(0,0,0,0.8)",
                "&:hover": {
                  background: "#fff",
                  color: colors.black,
                  boxShadow: "0 0 10px rgba(0,0,0,0.8)",
                },
              }}
            >
              Create Account
            </Button>
          </Stack>

          <Stack
            direction="row"
            gap={2}
            sx={{
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: 7,
            }}
          >
            <BannerImage
              img="/images/about-us/banner/challenges.png"
              text="Challenges"
              style={{
                filter: `drop-shadow(0 7px 30px #40F3F5)`,
              }}
            />
            <BannerImage
              img="/images/about-us/banner/crown.png"
              text="Showdowns"
              style={{ filter: `drop-shadow(0 7px 30px #F5A734)` }}
            />
            <BannerImage
              img="/images/about-us/banner/chest.png"
              text="Blessings"
              style={{
                filter: `drop-shadow(0 7px 30px #9055CB)`,
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TaglineBanner;
