import React from "react";
import { IconButton, Stack, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/system";
import { FaLinkedinIn } from "react-icons/fa";
import Name from "../atoms/Name";
import Role from "../atoms/Role";
import styled from "@emotion/styled";
import CircleImage from "../atoms/CircleImage";
import TwitterX from "components/Social/svgIcons/TwitterX";
import { BsPeopleFill } from "react-icons/bs";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "variant",
})(({ theme, variant }) => ({
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(0.5),
  position: "relative",
  justifyContent: "center",
  width: variant === "full" ? "100%" : 300,
  [theme.breakpoints.down("sm")]: { width: "100%" },
}));

const Content = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const FoundingCreator = ({ person, variant = "default" }) => {
  const theme = useTheme();
  if (person == null) return null;

  const { image, name, socials, role, audienceNumber } = person;

  return (
    <Wrapper variant={variant}>
      <CircleImage
        role="creators"
        image={image}
        name={name}
        width={135}
        variant="creator"
        style={{ zIndex: 9 }}
      />

      <Stack
        gap={3}
        align-items="center"
        justifyContent="center"
        sx={{
          width: "100%",
          minHeight: 170,
          background: theme.palette.colors.teamCard.background,
          padding: "40px 8px 8px 8px",
          borderRadius: "16px",
          marginTop: "-66px",
        }}
      >
        <Stack alignItems="center" justifyContent="center" gap={0.5}>
          <Name fontSize={26} name={name} textAlign="center" />
          <Role role={role} textAlign="center" />
        </Stack>
        {/* add quote for full view with quote */}
        {/* quotes not ready yet */}

        <Content>
          <Socials
            socials={socials}
            variant="creator"
            audienceNumber={audienceNumber}
          />
        </Content>
      </Stack>
    </Wrapper>
  );
};

const Socials = ({ socials, audienceNumber }) => {
  const theme = useTheme();
  const iconMapping = { twitter: <TwitterX />, linkedIn: <FaLinkedinIn /> };

  return (
    <Stack
      direction={{
        xs: "row",
        sm: "row",
        md: "row",
        lg: "column",
      }}
      alignItems="center"
      gap={2}
      sx={{
        position: "relative",
        zIndex: 10,
        [theme.breakpoints.down("lg")]: {
          justifyContent: "flex-start",
        },
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
      }}
    >
      {socials.map((social, i) => (
        <Link href={social.url} target="_blank">
          <Stack
            display="flex"
            direction="row"
            alignItems="center"
            gap={2}
            sx={{
              background: theme.palette.colors.gradient.horizontal,
              borderRadius: 10,
              padding: 0.5,
              marginBottom: "-65px",
              paddingRight: 3,
              maxHeight: "41px",
            }}
          >
            <IconButton
              sx={{
                background: "#000",
                zIndex: 9,
                maxHeight: "36px",
                "&:hover": {
                  background: "#000",
                  transform: "scale(1)",
                },
              }}
              color="white"
              href={social.url}
              target="_blank"
              key={`SOCIAL_${i}`}
              size="small"
            >
              {iconMapping[social.icon]}
            </IconButton>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography
                fontSize={18}
                variant="span"
                fontWeight="bold"
                component="span"
              >
                {audienceNumber}
              </Typography>
              <BsPeopleFill fontSize={18} color="#fff" />
            </Stack>
          </Stack>
        </Link>
      ))}
    </Stack>
  );
};

export default FoundingCreator;
