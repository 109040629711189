import { Stack } from "@mui/material";
import React from "react";
import Label from "components/Typography/Label";
import Logo from "components/GamePartners/atoms/Logo";
import styled from "@emotion/styled";
import { addAlphaToHex } from "helpers/colors";
import { isNullOrEmptyArray } from "helpers/display";

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: "16px 32px 8px 32px",
  background: addAlphaToHex(theme.palette.colors.black, 0.5),
  width: "auto",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 8,

  "& .logo": {
    margin: "16px",
  },
}));

const TeamLogos = ({ logos, title }) => {
  if (isNullOrEmptyArray(logos)) return null;

  return (
    <Wrapper>
      <Label textAlign="center">{title}</Label>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        {logos.map((item, i) => (
          <Logo
            key={i}
            alt={item.alt}
            src={item.src}
            title={item.title}
            href={item.href}
            width={item.width}
            height={item.height}
            desaturate={false}
          />
        ))}
      </Stack>
    </Wrapper>
  );
};

export default TeamLogos;
