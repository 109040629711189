import React from "react";
import MainHeader from "components/Typography/MainHeader";
import SocialValue from "pages/About/organisms/SocialValue";
import CompanyValues from "./organisms/CompanyValues";
import { Stack } from "@mui/material";
import TaglineBanner from "./organisms/TaglineBanner";

export const ABOUT_BREAKPOINT = 1270;

const About = () => {
  return (
    <Stack
      sx={{ alignItems: "center", justifyContent: "center" }}
      gap={{ xs: 10, sm: 10, md: 12 }}
    >
      <TaglineBanner />

      <Stack gap={10}>
        <MainHeader
          maxWidth={730}
          header="About Us"
          style={{ paddingLeft: 3, paddingRight: 3 }}
        />
        <CompanyValues />
        <SocialValue />
      </Stack>
    </Stack>
  );
};

export default About;
