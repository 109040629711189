import { colors } from "theme/colors";

export const input = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        transition: "0.2s all ease",
        borderRadius: "8px",
        fontWeight: 500,
        "& .MuiOutlinedInput-notchedOutline": {
          transition: "0.2s all ease",
        },
        "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
          {},
        "&:hover, &:active, &.Mui-focused": {},
        "&:hover": {},
        "&:active, &.Mui-focused": {},
      },
      input: {
        "&:focus": {
          borderRadius: "8px",
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        border: "1px solid transparent",
        borderRadius: "8px",
        fontWeight: 500,
        "&:hover": {},
        "&:active, &.Mui-focused": {},
        "&:hover:not(.Mui-disabled):before": {
          borderColor: "transparent",
        },
        "&::before": {
          borderColor: "transparent",
        },
        "&::after": {
          borderColor: "transparent",
        },
      },
      input: {
        borderColor: "transparent",
        "&:focus": {
          borderRadius: "8px",
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        background: colors.card.background,
        boxShadow: colors.elevation[3],

        "&:active, &.Mui-focused": {
          background: colors.card.background,
        },

        "& .MuiSelect-icon": {
          color: colors.white,
        },
      },
    },
  },
};
