import { colors } from "theme/colors";

export const modal = {
  MuiModal: {
    styleOverrides: {
      root: {
        "&.MuiDrawer-root .MuiPaper-root": {
          padding: "48px",
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: colors.black,
        "& .MuiList-root": {
          padding: 8,
          "& .MuiMenuItem-root": {
            fontWeight: 600,
            borderRadius: 4,
          },
        },
      },
    },
  },
};
