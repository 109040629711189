export const team = {
  best: {
    name: "David de-Vilder",
    role: "CEO",
    bio: "David's background is in software engineering, product development and business transformation. He has been investing in crypto since 2018 and was introduced to NFT gaming through Axie Infinity in 2020. He wrote the first version of axie.tech from his bedroom, built the first publicly available guild tools in NFT gaming and hosted the first ever NFT-based website ad banner. David boasts a successful 15-year career in fintech, including 8 years at Bank of America / Merrill Lynch and has built his own technology organisation in two previous companies.",
    image: "david.jpg",
    socials: [
      { icon: "twitter", url: "https://twitter.com/bestaxies" },
      { url: "https://www.linkedin.com/in/daviddevilder/", icon: "linkedIn" },
    ],
  },
  rare: {
    name: "Adam Arnold",
    role: "Marketing Director",
    bio: "Adam is a serial entrepreneur and innovator, applying technology to industries as diverse as real estate and biomedicine. Adam has been involved in NFT gaming since 2021 and is well-respected across a number of web3 communities. A Theo Paphitis SBS winner and former Ambassador to Peter Jones' Make Your Mark Campaign, Adam founded the first online estate agent to use virtual tours, and has spent over 15 years building and growing companies.",
    image: "adam.jpg",
    socials: [
      { icon: "twitter", url: "https://twitter.com/RareAxies" },
      {
        icon: "linkedIn",
        url: "https://www.linkedin.com/in/adam-arnold-78930339/",
      },
    ],
  },
  amy: {
    name: "Amy Peniston",
    role: "Product Director",
    bio: "Amy is a relentlessly creative front-end developer with a background in biomedical engineering and reinsurance data analytics. Amy is a lifelong gaming fan and discovered NFT gaming in 2021. Prior to Tribally, she created chillaxie.com, one of the most prominent fansites for Axie Infinity. Amy was invited to speak at NFT NYC in 2021 and AxieCon in 2022. With more than 10 years' experience building for the web, Amy thrives at the intersection of design and code.",
    image: "amy.jpg",
    socials: [
      { icon: "twitter", url: "https://twitter.com/amypeniston" },
      { icon: "linkedIn", url: "https://www.linkedin.com/in/amypeniston/" },
    ],
  },
  lev: {
    name: "Lev",
    role: "Managing Director",
    bio: "Lev is a seasoned management consultant with experience across procurement, finance, energy, pharmaceuticals and technology. He has previously worked alongside the UK government and Lloyds of London to implement policy changes and drive commercial best practice. A serial early-adopter, Lev has been researching and trading in the crypto space since 2012 and has been involved with NFT gaming since 2020.",
    image: "lev.jpg",
    socials: [{ icon: "twitter", url: "https://twitter.com/LevTrades" }],
  },
  ged: {
    name: "Ged",
    role: "Head of Operations",
    image: "ged.jpg",
    socials: [
      { icon: "twitter", url: "https://twitter.com/AxieGED21" },
      {
        url: "https://www.linkedin.com/in/kieron-geddes-46a78465/",
        icon: "linkedIn",
      },
    ],
    bio: "Ged is a qualified accountant & finance analyst with experience across finance, marketing & private sector. Ged was part of the original web3 gaming community back in 2017 as a collector of CryptoKitties and was one of the first people to join the Axie Infinity community in early 2018, at one point having the second largest collection of fuzzy origins & fuzzy mystic axies. Ged is an avid NFT collector with currently over 10,000 NFT's within his collection.",
  },
  davem: {
    name: "Dave M",
    role: "Principal Technical Architect",
    image: "davem.jpg",
    socials: [
      { icon: "twitter", url: "https://twitter.com/web3mzyrc" },
      {
        url: "https://www.linkedin.com/in/david-manwaring-29aa0a69/",
        icon: "linkedIn",
      },
    ],
    bio: "David is an accomplished server-side engineer and technical architect with the majority of his career spent working in fintech. David has always had an intense passion for tech and has always held an acute attention to detail on delivering what is most important to the end user. He's also a passionate console gamer and loves the horror survival genre.",
  },
  lysa: {
    name: "Lysa",
    role: "Web Developer",
    image: "lysa.jpg",
    socials: [{ icon: "twitter", url: "https://twitter.com/LysaUnku" }],
    bio: "Lysa has a background in both creative and analytical roles, before finding her true passion as a front-end developer utilising both skill sets. She is always learning, and thrives in the progressive and ever-changing environment of tech. Lysa loves building and creating innovative web apps with a focus on providing an excellent user experience. She is a lifelong fan of the fantasy genre, including books, films, and games.",
  },
  arun: {
    name: "Arun",
    role: "Senior Software Engineer",
    image: "arun.jpg",
    socials: [{ icon: "twitter", url: "https://twitter.com/ArunTribally" }],
    bio: "Arun is a postgraduate degree holder from the UK in computer science with solid experience in UI and server side development. In his career, he has always looked for work in unique and cutting-edge companies. He is a passionate engineer with a firm eye for perfection and always looks forward to learning something new to keep him updated. He is also a severe console gamer with no genre barriers in games.",
  },
  indes: {
    name: "Indes",
    role: "Product Analyst",
    image: "indes.jpg",
    socials: [{ icon: "twitter", url: "https://twitter.com/Indes_Tribally" }],
    bio: "Indes is an experienced, competitive player with an accomplished history in web3 gaming. He brings a unique perspective to Tribally’s product development, and represents our gamers, esports organisations and pro players. After being introduced to web3 gaming in mid-2020, he fell in love with Axie Infinity’s classic game. Indes went on to build a following on YouTube, through continued success in tournaments, arena seasons - winning seasons 5, 10, 16 & 19 - onboarding and coaching thousands of competitive players.",
  },
  lars: {
    name: "Lars",
    role: "Senior Software Engineer",
    image: "lars.jpg",
    socials: [
      { icon: "twitter", url: "https://twitter.com/wonder0xweird" },
      { icon: "linkedIn", url: "https://www.linkedin.com/in/larscrawford/" },
    ],
    bio: "Lars (aka w0nd3r) is a full stack engineer with a broad and diverse set of interests and skills. From synthesizing novel materials for brain computer interface devices to building crypto-native games, Lars is a tinkerer at heart who loves to think deeply about the universe, work on the cutting edge, and build joyous and useful things. Overall, he is a fun-loving, high energy builder with a passion for constructing the future today.",
  },
  iulian: {
    name: "Iulian",
    role: "DevOps Engineer",
    image: "iulian.jpg",
    socials: [
      {
        icon: "linkedIn",
        url: "https://www.linkedin.com/in/iulian-basangeac-72b086152/",
      },
    ],
    bio: `As a dedicated and results-driven system engineer with a specialized focus on DevOps, Iulian brings a wealth of experience in optimizing and automating cloud infrastructure. His technical proficiency spans various aspects of cloud computing, continuous integration, and continuous deployment (CI/CD) pipelines, ensuring robust and scalable solutions that enhance operational efficiency and streamline development processes. By staying current with the latest industry trends and best practices, Iulian strives to deliver innovative solutions that meet the evolving needs of businesses. He is a PC and console gamer, who enjoys many different genres of games.`,
  },
  teo: {
    name: "Teodoro",
    role: "Software Engineer",
    image: "teo.jpg",
    socials: [
      {
        icon: "linkedIn",
        url: "https://www.linkedin.com/in/teodoro-capacchione-a702651b8/",
      },
    ],
    bio: `Teo is a skilled software engineer with expertise in the MERN stack and the Godot engine, applying his talents to both professional and personal projects. His interest in programming was sparked in high school through game development and his favourite games are Platformers and FPS.`,
  },
  james: {
    name: "James",
    role: "Software Engineer",
    image: "james.jpg",
    socials: [
      {
        icon: "linkedIn",
        url: "https://www.linkedin.com/in/jamesowen24/",
      },
      {
        icon: "twitter",
        url: "https://x.com/jo0x18",
      },
    ],
    bio: `As a full stack engineer, James is passionate about building applications that provide real value to end users. He has a background in data engineering but felt he cared more about building products for people to use. He has a wide range of interests and hobbies and has been a follower of the Web3 space ever since watching Ready Player One. He loves all kinds of challenges and is currently willing to accept 1v1's on Rust, or in a real life boxing ring.`,
  },
  gunlocke: {
    name: "Gunlocke",
    role: "Marketing Analyst",
    image: "gunlocke.jpg",
    socials: [
      {
        icon: "linkedIn",
        url: "https://www.linkedin.com/in/dy-espina-37097b155/",
      },
      {
        icon: "twitter",
        url: "https://x.com/DyGunlocke",
      },
    ],
    bio: `Gunlocke brings over 15 years of expertise in marketing, automation, and customer service to Tribally. Throughout his career, he has developed a deep understanding of how to drive user engagement and growth in dynamic digital environments. At Tribally, Gunlocke is instrumental in shaping marketing strategies that resonate with the web3 gaming community, ensuring that players are not only engaged but also rewarded for their participation. His analytical skills and innovative approach help Tribally stay ahead in the rapidly evolving world of web3 gaming. Passionate about empowering gamers, Gunlocke is dedicated to enhancing the user experience and building a vibrant, connected community.`,
  },
};
