import { fontSizes } from "theme/fontSizes";
import Title from "components/Typography/Title";
import { useTheme } from "@mui/material";

const Name = ({ name, textAlign, fontSize }) => {
  const theme = useTheme();
  return (
    <Title
      variant="h4"
      color="#fff"
      className="name"
      textAlign={textAlign ? textAlign : "left"}
      fontSize={fontSize ? fontSize : fontSizes[30]}
      style={{
        lineHeight: 1.1,
        [theme.breakpoints.down("sm")]: { fontSize: 26 },
      }}
    >
      {name}
    </Title>
  );
};

export default Name;
