export const advisors = {
  aleksander: {
    name: "Aleksander Larsen",
    image: "aleksander.jpg",
    bio: "Co-Founder and COO @ Sky Mavis",
    quote:
      "The Tribally team has consistently been delivering on their vision and I'm excited to continue working with them. Both from a Sky Mavis and personal perspective.",
    socials: [
      { url: "https://www.linkedin.com/in/psycheout86/", icon: "linkedIn" },
      { url: "https://twitter.com/Psycheout86", icon: "twitter" },
    ],
    role: "advisors",
  },
  ramesh: {
    name: "Ramesh Nair",
    image: "ramesh.jpg",
    bio: "Veteran Blockchain Engineer and Angel Investor @hiddentao",
    quote:
      "The Tribally team are some of the most talented engineers and inspired entrepreneurs in the NFT gaming space, and I'm delighted to be supporting them technically.",
    socials: [
      { url: "https://www.linkedin.com/in/hiddentao/", icon: "linkedIn" },
      { url: "https://twitter.com/hiddentao", icon: "twitter" },
      { url: "https://github.com/hiddentao", icon: "github" },
    ],
    role: "advisors",
  },
  steve: {
    name: "Steve Tinkler",
    image: "steve.jpg",
    bio: "CEO @ LvlUp Partners (ex-Epic Games, Mediatonic)",
    quote:
      "Tribally are creating something truly exceptional, focussed on a sustainable ecosystem, taking principles from web2 practices and applying them to web3 gaming communities.",
    socials: [
      { url: "https://www.linkedin.com/in/steve-tinkler/", icon: "linkedIn" },
    ],
    role: "advisors",
  },
  tq: {
    name: "TQ",
    bio: "Web3 Gaming Lead @ Tier One Alliance and Founder of TQAid",
    image: "tq.jpg",
    socials: [{ icon: "twitter", url: "https://twitter.com/tq_games" }],
    quote:
      "The success of web3 gaming isn’t built on gameplay or monetization alone - it’s also the community behind it. Tribally understands this and hopes to inspire the gaming industry to keep investing in their community. Power to the gamers!",
    role: "advisors",
  },
  felix: {
    name: "Felix Wong",
    image: "felix.jpg",
    bio: "Partner @ LvlUp Partners (ex-Epic Games, Mediatonic)",
    quote:
      "Tribally’s vision is the future of gaming communities. I’m very excited to contribute to Tribally’s mission of creating a platform that fosters sustainable, passionate and highly engaged game ecosystems that is inclusive of all players, contributors and stakeholders around a game.",
    socials: [
      { url: "https://www.linkedin.com/in/felixcmwong/", icon: "linkedIn" },
    ],
    role: "advisors",
  },
  dope: {
    name: `DopeOxide`,
    image: "dope-oxide.jpg",
    bio: "KOL and Investor @ Base Ecosystem",
    quote: `Tribally is doing amazing things in the Web3 Gaming community World. Their team combines incredible technical skills with a fresh, forward-thinking approach. They're not just creating something new; they're building a sustainable and thriving community that both benefits the player, and the game.I'm genuinely excited to support their work and see where they go next.`,
    socials: [{ url: "https://x.com/DopeOxide", icon: "twitter" }],
    role: "advisors",
  },
  lima: {
    name: `Lima`,
    image: "lima.jpg",
    bio: "Brand Ambassador @ Ronin Network",
    quote:
      "Tribally is a testament to the extraordinary potential that emerges when relentless innovation meets a deep understanding of communities. The team’s adeptness in fostering growth worldwide is unmatched. In the face of a bear market, where many have paused, Tribally has doubled down, building without cease. My belief in their mission is unwavering; their professional approach and proven track record of excellence resonate with my own commitment to the future. As we stand on the brink of a new era in gaming, I am proud to be part of a team that not only understands the pulse of the industry but also beats in unison with the heart of the community’s spirit.",
    socials: [{ url: "https://x.com/Lima_Kind", icon: "twitter" }],
    role: "advisors",
  },
  efensie: {
    name: "Efensie",
    bio: "Rapper & Content Creator @ Efensie Music",
    image: "efensie.jpg",
    socials: [
      {
        icon: "twitter",
        url: "https://x.com/EfensieMusic",
      },
    ],
    role: "advisors",
    quote: `I have never seen another company that gives back to their community more than Tribally does. I'm excited to be working alongside them to bring the gaming world on-chain.`,
  },
  james: {
    name: "James Hursthouse",
    bio: "Founder and CEO @ Greenstone Initiatives",
    image: "james-hursthouse.jpg",
    socials: [
      {
        icon: "twitter",
        url: "https://x.com/jameshursthouse",
      },
    ],
    role: "advisors",
    quote: `Tribally instantly adds exciting new dimensions to video gaming across the board - blending the best of Web3 and traditional gaming into a compelling offering for creators and gamers alike.`,
  },
  quinn: {
    name: "Quinn Campbell",
    bio: "Co-Founder & Growth Lead @ PTAL",
    image: "quinn.png",
    socials: [
      {
        icon: "twitter",
        url: "https://twitter.com/Quinn2992",
      },
      {
        icon: "linkedIn",
        url: "https://www.linkedin.com/in/qcampbell3",
      },
    ],
    role: "advisors",
    quote: `Tribally embodies the future of gaming— focused on community, sustainability, and value participation. I’m proud to be part of their journey as they lead the charge in transforming the web3 gaming ecosystem.`,
  },
  hidden: {
    name: "",
    role: "",
    image: "hidden.png",
    socials: [],
  },
};
