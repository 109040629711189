import React from "react";
import { useTheme } from "@mui/system";
import { navLinks } from "components/Navigation/Utils/data";
import GoHomeButton from "components/Navigation/GoHomeButton";
import { Stack, Typography } from "@mui/material";
import CustomLink from "components/CustomLink";

const FooterLinks = () => {
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="center"
      gap={3}
      sx={{ background: theme.palette.colors.black }}
    >
      {navLinks.map((item, i) => (
        <CustomLink to={item.to} href={item.href} key={i}>
          <Typography
            fontWeight={600}
            sx={{ fontSize: "0.9rem", "&:hover": { color: "#10E8DD" } }}
          >
            {item.text}
          </Typography>
        </CustomLink>
      ))}
      <GoHomeButton />
    </Stack>
  );
};

export default FooterLinks;
