export const TRIBALLY_EMAIL =
  "mailto:rare@tribally.games?subject=Supercharge my Community";
export const TRIBALLY_TWITTER = "https://twitter.com/TriballyGames";
export const TRIBALLY_DISCORD = "https://discord.gg/tribally";
export const TRIBALLY_SUBSTACK = "https://tribally.substack.com/";
export const TRIBALLY_COMPANY_NAME = "Tribally";
export const TRIBALLY_URL = "https://tribally.games";
export const TRIBALLY_CONTACT_EMAIL = "mailto:info@tribally.games";

export const PARTNERSHIPS_URL = "/partnerships";

export const TRIBALLY_TITLE = "Tribally";
export const TRIBALLY_TAGLINE = "Gaming with Benefits";
export const SEO_DESCRIPTION =
  "Game with friends, earn $TRIBAL and win prizes!";

export const TRIBALLY_TEAM_DESCRIPTION =
  "The Tribally team has a wealth of technical experience, accumulated in well respected firms spanning multiple industries.";
export const TRIBALLY_ADVISORS_DESCRIPTION =
  "Our advisory team is forged from individuals within industry leading firms, at the intersection of web3 & gaming.";
export const TRIBALLY_CREATORS_DESCRIPTION =
  "Our Team of Founding Creators is made up from prominent gamers and content creators.";

export const MAILCHIMP_ACTION_URL =
  "https://games.us21.list-manage.com/subscribe/post?u=a8ad476a6872a623ebcecd335&id=13f7d94dd9";

export const TRIBALLY_WHITEPAPER_URL = "https://whitepaper.tribally.games";
export const TRIBALLY_INVESTOR_DASHBOARD = "https://presale.tribally.games";
