import React from "react";
import { Stack } from "@mui/material";
import Copyright from "../atoms/Copyright";
import CustomLink from "components/CustomLink";
import Text from "components/Typography/Text";

const SiteInfo = () => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="center"
      justifyContent="center"
      gap={2}
      sx={{
        opacity: 0.3,
        whiteSpace: "nowrap",
        transition: "opacity 0.3s ease-in-out",
        "&:hover": {
          opacity: 1,
        },
      }}
    >
      <Copyright />
      <CustomLink href="https://tribally.games/terms-and-conditions">
        <Text
          color="bright"
          fontSize={12}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Terms and Conditons
        </Text>
      </CustomLink>
      <CustomLink href="https://tribally.games/privacy-policy">
        <Text
          color="bright"
          fontSize={12}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Privacy Policy
        </Text>
      </CustomLink>
    </Stack>
  );
};

export default SiteInfo;
