export const appBar = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        background: "transparent",
        boxShadow: "none",
      },
    },
  },
};
