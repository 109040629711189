import { colors } from "theme/colors";
import { addAlphaToHex } from "helpers/colors";

export const BUTTON_BORDER_RADIUS_LARGE = 8;
export const BUTTON_BORDER_RADIUS_MEDIUM = 6;
export const BUTTON_BORDER_RADIUS_SMALL = 6;

export const BUTTON_FONT_LARGE = 16;
export const BUTTON_FONT_MEDIUM = 15;
export const BUTTON_FONT_SMALL = 12;

export const BUTTON_PADDING_LARGE = 24;
export const BUTTON_PADDING_MEDIUM = 20;
export const BUTTON_PADDING_SMALL = 16;

export const BUTTON_HEIGHT_LARGE = 48;
export const BUTTON_HEIGHT_MEDIUM = 40;
export const BUTTON_HEIGHT_SMALL = 30;

export const BUTTON_HOVER_SCALE = "scale(1.03)";

export const buttons = {
  MuiButton: {
    variants: [
      {
        props: { variant: "white" },
        style: {
          background: colors.white,
          color: colors.black,
          textTransform: "uppercase",
          boxShadow: "none",
          fontWeight: 700,
          transition: "0.2s all ease",
          whiteSpace: "nowrap",
          height: BUTTON_HEIGHT_LARGE,
          fontSize: BUTTON_FONT_LARGE,
          paddingLeft: BUTTON_PADDING_LARGE,
          paddingRight: BUTTON_PADDING_LARGE,
          borderRadius: BUTTON_BORDER_RADIUS_LARGE,
          "&:hover": {
            background: colors.white,
            transform: BUTTON_HOVER_SCALE,
          },
        },
      },
    ],
    styleOverrides: {
      contained: {
        background: colors.purple600,
        color: colors.white,
        textTransform: "none",
        border: "none",
        fontWeight: 700,
        lineHeight: 1,
        transition: "0.2s transform ease",
        whiteSpace: "nowrap",
        boxShadow:
          "0px -10px 10px 0px rgba(38, 8, 77, 0.10) inset, 0px -1px 0px 0px rgba(38, 8, 77, 0.25) inset, 0px 10px 16px 0px rgba(185, 130, 255, 0.30) inset, 0px 1px 0px 0px rgba(185, 130, 255, 0.18) inset",
        "&:hover": {
          background: colors.purple600,
          color: colors.white,
          boxShadow:
            "0px -10px 10px 0px rgba(38, 8, 77, 0.10) inset, 0px -1px 0px 0px rgba(38, 8, 77, 0.25) inset, 0px 10px 16px 0px rgba(185, 130, 255, 0.30) inset, 0px 1px 0px 0px rgba(185, 130, 255, 0.18) inset",
          transform: BUTTON_HOVER_SCALE,
        },
      },
      containedPrimary: {},
      containedSecondary: {},
      containedSizeSmall: {
        height: BUTTON_HEIGHT_SMALL,
        fontSize: BUTTON_FONT_SMALL,
        paddingLeft: BUTTON_PADDING_SMALL,
        paddingRight: BUTTON_PADDING_SMALL,
        borderRadius: BUTTON_BORDER_RADIUS_SMALL,
      },
      containedSizeMedium: {
        height: BUTTON_HEIGHT_MEDIUM,
        fontSize: BUTTON_FONT_MEDIUM,
        paddingLeft: BUTTON_PADDING_MEDIUM,
        paddingRight: BUTTON_PADDING_MEDIUM,
        borderRadius: BUTTON_BORDER_RADIUS_MEDIUM,
      },
      containedSizeLarge: {
        height: BUTTON_HEIGHT_LARGE,
        fontSize: BUTTON_FONT_LARGE,
        paddingLeft: BUTTON_PADDING_LARGE,
        paddingRight: BUTTON_PADDING_LARGE,
        borderRadius: BUTTON_BORDER_RADIUS_LARGE,
      },
      outlined: {},
      outlinedPrimary: {},
      outlinedSecondary: {},
      outlinedSizeSmall: {},
      outlinedSizeMedium: {},
      outlinedSizeLarge: {},
      text: {},
      textSizeSmall: {},
      textSizeMedium: {},
      textSizeLarge: {},
      iconSizeSmall: {
        svg: {
          width: BUTTON_FONT_SMALL,
          height: BUTTON_FONT_SMALL,
        },
      },
      iconSizeMedium: {
        svg: {
          width: BUTTON_FONT_MEDIUM,
          height: BUTTON_FONT_MEDIUM,
        },
      },
      iconSizeLarge: {
        svg: {
          width: BUTTON_FONT_LARGE,
          height: BUTTON_FONT_LARGE,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          backgroundColor: addAlphaToHex(colors.secondary, 0.1),
          transform: "scale(1.15)",
        },
      },
    },
  },
};
