import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import CustomLink from "components/CustomLink";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  img: {
    width,
  },
}));

const Image = ({ width, href, onClick, src, alt, className }) => {
  return (
    <CustomLink onClick={onClick} href={href}>
      <Wrapper width={width} className={className}>
        <img src={src} alt={alt} />
      </Wrapper>
    </CustomLink>
  );
};

export default Image;
