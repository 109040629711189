import { Stack, useTheme } from "@mui/material";

const CircleImage = ({
  image,
  name,
  width,
  role,
  style,
  variant = "default",
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        ...style,
        border:
          variant === "team"
            ? `solid 8px ${theme.palette.colors.teamCard.background}`
            : `solid 15px ${theme.palette.colors.teamCard.background}`,
        borderRadius: "50%",
        "& img": {
          minHeight: width,
          minWidth: width,
          maxHeight: width,
          maxWidth: width,
          borderRadius: "50%",
          width: "100%",
        },
      }}
    >
      <img src={`/images/${role}/${image}`} alt={`${name} - Tribally`} />
    </Stack>
  );
};

export default CircleImage;
