import { Button } from "@mui/material";
import { TRIBALLY_URL } from "config";
import { FaArrowRight } from "react-icons/fa";
import CustomLink from "components/CustomLink";

const GoHomeButton = () => {
  return (
    <CustomLink href={TRIBALLY_URL}>
      <Button variant="contained" size="small" endIcon={<FaArrowRight />}>
        Go Home
      </Button>
    </CustomLink>
  );
};

export default GoHomeButton;
