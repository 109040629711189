import { colors } from "./colors";
import { appBar } from "./components/appBar";
import { baseline } from "./components/baseline";
import { buttons } from "./components/buttons";
import { chip } from "./components/chip";
import { divider } from "./components/divider";
import { drawer } from "./components/drawer";
import { input } from "./components/input";
import { link } from "./components/link";
import { modal } from "./components/modal";
import { typography } from "./components/typography";
import { fonts } from "./fonts";

const theme = {
  typography: {
    fontFamily: fonts.body,
  },
  components: {
    ...baseline,
    ...typography,
    ...buttons,
    ...appBar,
    ...modal,
    ...drawer,
    ...input,
    ...chip,
    ...link,
    ...divider,
  },
  palette: {
    colors,
    primary: {
      main: colors.primary,
      contrastText: colors.black,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    white: {
      main: colors.white,
      contrastText: colors.primary,
    },
    black: {
      main: colors.black,
      contrastText: colors.primary,
    },
    text: {
      primary: colors.primary,
      secondary: colors.secondary,
      bright: colors.white,
      dark: colors.black,
      muted: colors.muted,
      veryMuted: colors.veryMuted,
    },
    background: {
      default: colors.black,
    },
  },
};

export default theme;
