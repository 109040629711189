export const makeCaseStudyUrlFromSlug = (slug) => {
  if (!slug) return undefined;

  return `/partnerships/${slug}`;
};

export const isNullOrEmptyString = (val) => {
  if (val == null || val === "") return true;
  return false;
};

export const isNullOrEmptyArray = (val) => {
  if (val == null || !Array.isArray(val) || val.length === 0) return true;
  return false;
};
