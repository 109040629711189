import { Stack } from "@mui/material";
import React from "react";

const SectionHeader = ({
  children,
  alignItems = "center",
  gap = 1.2,
  maxWidth = 500,
  style,
}) => {
  return (
    <Stack
      gap={gap}
      alignItems={alignItems}
      justifyContent="flex-start"
      sx={{
        maxWidth: maxWidth,
        m: "0 auto",
        position: "relative",
        zIndex: 2,
        ...style,
      }}
    >
      {children}
    </Stack>
  );
};

export default SectionHeader;
