import { TRIBALLY_WHITEPAPER_URL } from "config";

export const navLinks = [
  {
    text: "About",
    to: "/",
  },
  {
    text: "Team",
    to: "/team",
  },
  {
    text: "Invest",
    to: "/invest",
  },
  {
    text: "Whitepaper",
    href: TRIBALLY_WHITEPAPER_URL,
  },
];
