import React from "react";
import Text from "components/Typography/Text";
import { useTheme } from "@mui/material";
import { fontSizes } from "theme/fontSizes";

const Bio = ({ bio }) => {
  const theme = useTheme();

  return (
    <Text
      textAlign="left"
      fontSize={fontSizes[15]}
      style={{
        lineHeight: 1.5,
        [theme.breakpoints.down("lg")]: {
          textAlign: "left",
        },
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
        },
      }}
    >
      {bio}
    </Text>
  );
};

export default Bio;
