import { TRIBALLY_TAGLINE, TRIBALLY_TITLE } from "config";

export const augmentTitle = (title) => `${title} | ${TRIBALLY_TITLE}`;

export const seo = {
  partnerships: {
    title: augmentTitle("Partnerships"),
    description:
      "Tribally partners with new and existing NFT games developers to build, promote, educate and onboard.",
  },
  preregister: {
    title: augmentTitle("Pre-register"),
    description:
      "Tribally partners with new and existing NFT games developers to build, promote, educate and onboard.",
  },
  about: {
    title: augmentTitle("About"),
    description:
      "The Tribally Platform provides games with player data across multiple chains & gaming platforms, unlocking a deeper understanding of their user population & target audience. Connect with Tribally to supercharge your user acquisition, engagement & retention strategies.",
  },
  team: {
    title: augmentTitle("Team"),
    description:
      "Through the Tribally Platform, we aim to build the world’s most valuable gaming communities and our ambitious vision is backed by a team that embodies our key principles for success. Check out the wealth of technical experience across our founders, core team & advisors.",
  },
  terms: {
    title: augmentTitle("Terms and Conditions"),
    description: TRIBALLY_TAGLINE,
  },
  privacyPolicy: {
    title: augmentTitle("Privacy Policy"),
    description: TRIBALLY_TAGLINE,
  },
  invest: {
    title: augmentTitle("Invest"),
    description: TRIBALLY_TAGLINE,
  },
};
