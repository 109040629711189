import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { ReactComponent as TriballySvg } from "icons/tribally-logo-mark.svg";
import { keyframes } from "@emotion/react";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: 8,
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  opacity: 0.2,

  svg: {
    height: 30,
    width: "auto",
    display: "block",
    animation: `${pulse} 3s ease-in-out infinite`,
  },
}));

const Line = styled(Stack)(({ side }) => ({
  width: "100%",
  height: 2,
  background:
    side === "right"
      ? "linear-gradient(to left, transparent, #fff)"
      : "linear-gradient(to right, transparent, #fff)",
}));

const Divider = () => {
  return (
    <Wrapper>
      <Line side="left" />
      <Stack>
        <TriballySvg />
      </Stack>
      <Line side="right" />
    </Wrapper>
  );
};

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export default Divider;
