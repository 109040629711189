import { Stack, Typography, useTheme } from "@mui/material";
import Text from "components/Typography/Text";
import { fonts } from "theme/fonts";
import { colors } from "theme/colors";

const Principle = ({ icon, text, title }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      flexWrap="wraps"
      alignItems="center"
      sx={{
        flexDirection: "row",
        maxWidth: "45%",
        [theme.breakpoints.down("lg")]: {
          maxWidth: "65%",
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "90%",
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      }}
    >
      <Stack
        width="180px"
        minWidth="180px"
        height="180px"
        sx={{
          marginRight: "-90px",
          zIndex: 9,
          alignItems: "center",
          justifyContent: "center",
          background: "#182237",
          filter: `drop-shadow(0px 0px 8px ${colors.background})`,
          border: `2px solid ${colors.purple400}`,
          borderRadius: "16px",
          [theme.breakpoints.down("sm")]: {
            height: "100px",
            marginRight: "0px",
            marginBottom: "-60px",
          },
        }}
      >
        <Typography
          sx={{
            svg: { fontSize: "2rem", color: colors.purple400 },
            path: { stroke: colors.purple400 },
          }}
        >
          {icon}
        </Typography>
        <Text
          style={{
            fontFamily: fonts.header,
          }}
        >
          {title}
        </Text>
      </Stack>
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          background: "#182237",
          borderRadius: "16px",
          padding: "20px 20px 20px 110px",
          height: 180,
          [theme.breakpoints.down("xl")]: { height: 180 },
          [theme.breakpoints.down("lg")]: { height: "auto" },
          [theme.breakpoints.down("md")]: { height: "auto" },
          [theme.breakpoints.down("sm")]: {
            height: "auto",
            padding: "80px 20px 20px 20px",
          },
        }}
      >
        <Text
          fontSize="0.9rem"
          style={{
            lineHeight: 1.5,
            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
            },
          }}
          color={colors.typography.muted}
        >
          {text}
        </Text>
      </Stack>
    </Stack>
  );
};

export default Principle;
