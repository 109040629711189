import { Stack, Typography, useTheme } from "@mui/material";

const BannerImage = ({ text, img, style }) => {
  const theme = useTheme();
  return (
    <Stack
      align="center"
      sx={{
        background: "rgba(0, 0, 0, .8)",
        borderRadius: 4,
        padding: 2,
        maxWidth: 190,
      }}
    >
      <img src={img} alt={text} style={{ maxWidth: 150, ...style }} />
      <Typography
        sx={{
          color: theme.palette.colors.white,
          fontWeight: 700,
          fontSize: 17,
          position: "relative",
          zIndex: 2,
          textShadow: "0 0 5px rgba(0,0,0,1)",
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
          },
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default BannerImage;
