import React from "react";
import { Typography } from "@mui/material";

const SubTitle = ({
  children,
  color = "#fff",
  textAlign,
  fontSize = 32,
  style,
  variant = "h3",
}) => {
  return (
    <Typography
      variant={variant}
      color={color}
      textAlign={textAlign}
      fontSize={fontSize}
      sx={style}
    >
      {children}
    </Typography>
  );
};

export default SubTitle;
