import React from "react";
import { Stack, Button, Box } from "@mui/material";
import Title from "components/Typography/Title";
import { keyframes } from "@emotion/react";
import { TRIBALLY_WHITEPAPER_URL, TRIBALLY_INVESTOR_DASHBOARD } from "config";
import Text from "components/Typography/Text";
import CustomLink from "components/CustomLink";
import { useTheme } from "@mui/system";
import SubTitle from "components/Typography/SubTitle";
import { IoPlay } from "react-icons/io5";

const Invest = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        py: 10,
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "url(/images/backgrounds/tribal-background.jpg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      gap={6}
    >
      <Stack
        sx={{
          animation: `${pulse} 3s ease-in-out infinite`,
        }}
      >
        <img width={200} src="/images/invest/tribal.png" alt="$TRIBAL" />
      </Stack>
      <Stack
        gap={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Title
          variant="h1"
          fontSize={80}
          style={{
            color: `#FF9900`,
            lineHeight: 1,
            textAlign: "center",
            maxWidth: 560,
            margin: "0 auto",
            fontWeight: 800,
            textTransform: "uppercase",
            [theme.breakpoints.down("sm")]: {
              fontSize: 50,
            },
          }}
        >
          $TRIBAL
        </Title>
        <SubTitle
          style={{
            textTransform: "uppercase",
            [theme.breakpoints.down("sm")]: {
              fontSize: 30,
            },
          }}
          fontSize={60}
        >
          Public Token Sale
        </SubTitle>
        <Text
          fontWeight={700}
          fontSize={32}
          textAlign="center"
          style={{
            [theme.breakpoints.down("lg")]: {
              fontSize: 28,
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: 20,
            },
          }}
        >
          Early Access: 21st November 2024
          <br /> Public Sale: 4th December 2024
        </Text>
      </Stack>
      <Button
        endIcon={<IoPlay />}
        variant="contained"
        size="large"
        href={TRIBALLY_INVESTOR_DASHBOARD}
        sx={{
          maxWidth: 200,
          width: "100%",
          background: "#fff",
          color: "#000000",
          boxShadow: "0 0 10px rgba(0,0,0,0.8)",
          "&:hover": {
            background: "#fff",
            color: "#000000",
            boxShadow: "0 0 10px rgba(0,0,0,0.8)",
          },
        }}
      >
        Buy $TRIBAL
      </Button>

      <CustomLink href={TRIBALLY_INVESTOR_DASHBOARD}>
        <Box
          sx={{
            maxWidth: 800,
            width: "100%",
            borderRadius: 2,
            overflow: "hidden",
            px: 2,
          }}
        >
          <img
            src="/images/invest/tribal-public-sale.jpg"
            alt="$TRIBAL Public Sale"
            width="100%"
          />
        </Box>
      </CustomLink>

      <Stack direction="column" alignItems="center" justifyContent="center">
        <CustomLink style={{ color: "#fff" }} href={TRIBALLY_WHITEPAPER_URL}>
          <Text
            style={{
              fontSize: 12,
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "none",
              },
            }}
          >
            Read Whitepaper
          </Text>
        </CustomLink>
      </Stack>
    </Stack>
  );
};

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
`;

export default Invest;
