import { addAlphaToHex } from "helpers/colors";
import { colors } from "theme/colors";

export const baseline = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        color: colors.white,
        scrollbarColor: `${colors.scrollbar.thumb} ${colors.scrollbar.background}`,
        "& ::selection": {
          background: addAlphaToHex(colors.primary, 0.4),
          color: colors.white,
          textFillColor: "initial",
        },
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: colors.scrollbar.background,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: colors.scrollbar.thumb,
          minHeight: 24,
          border: `3px solid ${colors.scrollbar.background}`,
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: colors.scrollbar.thumbHover,
          },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: colors.scrollbar.thumbHover,
          },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: colors.scrollbar.thumbHover,
          },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: colors.scrollbar.background,
        },
      },
    },
  },
};
