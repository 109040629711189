import {
  FaStar,
  FaHandshake,
  FaHandHoldingHeart,
  FaCheck,
} from "react-icons/fa";
import { RiScales3Fill } from "react-icons/ri";
import { SlSpeech } from "react-icons/sl";
import { BsPeopleFill } from "react-icons/bs";
import { GrGrow } from "react-icons/gr";

export const communityPrinciples = [
  {
    icon: <FaStar />,
    title: "Morailty",
    text: "Being considerate and aware of the morality behind our actions within the community is paramount. Our community should strive to do what is righteous by others around them.",
  },
  {
    icon: <FaHandshake />,
    title: "Respect",
    text: "Upholding the standard of being respectful in all accounts, treating others as you would intend to be treated yourself. Remaining respectful to one another, holding each other accountable to high standards.",
  },
  {
    icon: <RiScales3Fill />,
    title: "Equality & Fairness",
    text: "We are a global community. We’re all gamers, so we should be treated equally, creating fair, welcoming environments. Any forms of discriminatory behavior will not be tolerated within this community.",
  },
  {
    icon: <GrGrow />,
    title: "Helpful By Nature",
    text: "Community members may vary in their experience as a gamer/member. Within Tribally it is a core principle to be helpful and kind. Providing a culture of passing such acts of kindness forwards and throughout the community",
  },
  {
    icon: <SlSpeech />,
    title: "Communication",
    text: "Not only the way we communicate with others in this space but also the willingness to communicate. It is important that as a community member you are willing to communicate in a respectful manner.",
  },
  {
    icon: <BsPeopleFill />,
    title: "Collaboration",
    text: "Be collaborative at core! This space allows all walks of people to come together. It should be celebrated and championed to collaborate within our community and build lasting relationships with one another",
  },
  {
    icon: <FaHandHoldingHeart />,
    title: "Responsibility",
    text: "We are all individually responsible for our actions within this community, empower yourself and those around you by being a responsible leader, lead with your actions, lead with your words and show empathy to those around you.",
  },
  {
    icon: <FaCheck />,
    title: "Accountability",
    text: "We pride ourselves on being able to facilitate friendly communities of gamers and their collaborative experiences, the ability to maintain this comes from the accountability the community takes over each other's actions. A responsible community is a long lasting one.",
  },
];
