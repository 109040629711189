import CookieConsent from "react-cookie-consent";
import { useMediaQuery, useTheme } from "@mui/material";

const CookieConsentBanner = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <CookieConsent
      location="bottom"
      buttonText="accept"
      cookieName="tribally_web_cookie_consent"
      style={{
        background: "rgba(14, 21, 33, 0.98)",
        padding: smDown ? "8px" : mdDown ? "16px" : "24px 32px",
        borderRadius: "16px",
        alignItems: "center",
        border: `3px solid #329cff`,
        maxWidth: smDown ? "100%" : "1200px",
        right: smDown ? "initial" : 0,
        margin: smDown ? "0" : "0 auto 16px auto",
        flexDirection: smDown ? "column" : "row",
      }}
      buttonStyle={{
        color: "#fff",
        background: `linear-gradient(90deg, #10DBE8 0%, #329cff 59.9%, #9e8fea 100%)`,
        fontWeight: "700",
        fontSize: "18px",
        borderRadius: "8px",
        padding: "12px 22px",
        "&:hover": {
          transform: "scale(1.1)",
        },
      }}
      contentStyle={{
        lineHeight: 1.4,
        color: "#fff",
        flexBasis: smDown ? "100px" : "300px",
        textAlign: smDown ? "center" : "left",
        fontSize: smDown ? "15px" : "18px",
      }}
      expires={365}
    >
      We use cookies to enhance your browsing experience and analyze site
      traffic.
      <br />
      By continuing to use this site, you consent to our use of cookies.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
