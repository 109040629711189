import { Stack } from "@mui/material";
import { team } from "../data/teamData";
import styled from "@emotion/styled";
import TeamMember from "../molecules/TeamMember";

const Wrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(6),
  maxWidth: "1200px",
  margin: "0 auto",
  [theme.breakpoints.down("xl")]: { width: "100%" },
}));

const Employees = () => {
  return (
    <Wrapper>
      <TeamMember variant="team" person={team.davem} />
      <TeamMember variant="team" person={team.arun} />
      <TeamMember variant="team" person={team.lysa} />
      <TeamMember variant="team" person={team.indes} />
      <TeamMember variant="team" person={team.lars} />
      <TeamMember variant="team" person={team.iulian} />
      <TeamMember variant="team" person={team.teo} />
      <TeamMember variant="team" person={team.james} />
      <TeamMember variant="team" person={team.gunlocke} />
    </Wrapper>
  );
};

export default Employees;
