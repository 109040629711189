import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "theme";
import { StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import CustomRoutes from "./CustomRoutes";
import Stars from "components/Stars";
import ScrollToTop from "components/Utility/ScrollToTop";
import CookieConsentBanner from "components/CookieConsent/CookieConsentBanner";

function App() {
  const customTheme = createTheme(theme);

  return (
    <ThemeProvider theme={customTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <Router>
          <ScrollToTop />
          <Stars />
          <CssBaseline />
          <CustomRoutes />
          <CookieConsentBanner />
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
