import { addAlphaToHex } from "helpers/colors";

const neutral = {
  black800: "#0F1421",
  black700: "#182236",
  black600: "#222D43",
  black500: "#465475",
  black400: "#627194",
  black300: "#AFBCDB",
  black200: "#D0DBF2",
  black100: "#EBEFFA",
  black: "#10151F",
  white: "#ffffff",
};

const mainColors = {
  teal: "#10E8DD",
  purple: "#c464ff",
  blue: "#0E7EE5",
};

const purples = {
  purple400: "#AB69FF",
  purple500: "#9847FF",
  purple600: "#7D2CE5",
};

export const colors = {
  ...neutral,
  ...purples,
  muted: "#AFBCDB",
  veryMuted: "#627194",
  shadow: "#041e2e",
  background: "#080D17",
  primary: mainColors.teal,
  secondary: mainColors.purple,
  tertiary: mainColors.blue,
  gradient: {
    horizontal: "linear-gradient(90deg, #10DBE8 0%, #0E7EE5 50%, #9D50FF 100%)",
    orange: "linear-gradient(to right, #FD2910, #FF9900)",
  },
  scrollbar: {
    background: neutral.black800,
    thumb: neutral.black300,
    thumbHover: mainColors.purple,
  },
  typography: {
    main: neutral.white,
    muted: "#EBEFFA",
  },
  card: {
    background: `${addAlphaToHex(neutral.black, 0.6)}`,
    glow: "#11334c",
  },
  elevation: {
    3: "box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.6)",
  },
  teamCard: {
    background: "#182237",
    teamBackground: "#94D8FF",
    border: "#94d8ff1a",
  },
};
