import { Link, Stack } from "@mui/material";
import React from "react";
import { TRIBALLY_EMAIL } from "config";
import { FaEnvelope } from "react-icons/fa";

const EmailButton = ({ color = "#fff", height, width, padding = 5 }) => {
  return (
    <Link href={TRIBALLY_EMAIL} target="_blank" title="Tribally Email">
      <Stack
        maxWidth={width}
        width={width}
        padding={padding}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundImage: `url(/images/about-us/socials-button.png)`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          color,
          svg: {
            height: height,
            width: "auto",
          },
          "&:hover": {
            transform: "scale(1.04)",
          },
        }}
      >
        <FaEnvelope />
      </Stack>
    </Link>
  );
};

export default EmailButton;
