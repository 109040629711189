import React from "react";
import { useTheme } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { fonts } from "theme/fonts";
import DiscordButton from "components/Social/RoundButtons/DiscordButton";
import TwitterButton from "components/Social/RoundButtons/TwitterButton";
import { colors } from "theme/colors";

const SocialCTA = ({ title, text, style }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        width: "100%",
        overflow: "hidden",
        position: "relative",
        alignSelf: "center",
        backgroundImage: "url(/images/backgrounds/tagline-banner.jpg)",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "20px",
        py: 4,
        px: 4,
        ...style,
      }}
    >
      <Stack
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: colors.purple600,
          opacity: 0.5,
        }}
      />
      <Stack
        alignItems="center"
        gap={2}
        sx={{ position: "relative", zIndex: 2 }}
      >
        {title ? (
          <Typography
            variant="span"
            fontWeight={700}
            fontSize={40}
            fontFamily={fonts.header}
            textAlign="center"
            sx={{
              textShadow: "0px 0px 8px #000",
              lineHeight: 1.2,
              [theme.breakpoints.down("md")]: {
                fontSize: 32,
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: 26,
              },
            }}
          >
            {title}
          </Typography>
        ) : null}
        {text ? (
          <Typography
            variant="span"
            fontWeight={800}
            fontSize={20}
            textAlign="center"
            sx={{
              textShadow: "0px 0px 8px #000",
              lineHeight: 1.2,
              [theme.breakpoints.down("md")]: {
                fontSize: 18,
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: 16,
              },
            }}
          >
            {text}
          </Typography>
        ) : null}

        <Stack
          gap={{ xs: 2, sm: 2, md: 4 }}
          direction={{ xs: "column", sm: "column", md: "row" }}
          sx={{ alignItems: "center" }}
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <DiscordButton height={35} />
            <TwitterButton height={31} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SocialCTA;
