import { Typography } from "@mui/material";
import React from "react";
import { fontSizes } from "theme/fontSizes";

const Label = ({
  className,
  children,
  textAlign,
  large = false,
  color = "#fff",
}) => {
  return (
    <Typography
      fontSize={large ? fontSizes[18] : fontSizes[14]}
      variant="h4"
      color={color}
      fontWeight={700}
      className={className}
      textTransform="uppercase"
      letterSpacing="0.5px"
      textAlign={textAlign}
    >
      {children}
    </Typography>
  );
};

export default Label;
