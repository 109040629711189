import { IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/system";
import { FaLinkedinIn } from "react-icons/fa";
import NamePlate from "../atoms/NamePlate";
import Bio from "../atoms/Bio";
import styled from "@emotion/styled";
import CircleImage from "../atoms/CircleImage";
import TwitterX from "components/Social/svgIcons/TwitterX";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    !["variant", "width", "flexBasis"].includes(prop),
})(({ theme, flexBasis, variant }) => ({
  alignItems: "center",
  gap: 16,
  flexGrow: 0,
  flexBasis: flexBasis || "auto",
  width: variant === "team" ? 350 : "100%",
  [theme.breakpoints.down("lg")]: {
    width: variant === "team" ? 320 : "100%",
    gap: 8,
    flexBasis: "auto",
  },
  [theme.breakpoints.down("sm")]: { width: "100%" },
}));

const TeamMember = ({ person, width, variant, style, flexBasis }) => {
  const theme = useTheme();
  if (person == null) return null;

  const { image, name, role, bio, socials } = person;

  return (
    <Wrapper variant={variant} flexBasis={flexBasis} sx={{ ...style }}>
      <Stack
        width="100%"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-end"
        gap={1}
        sx={{
          marginBottom: "-100px",
          marginLeft: "-40px",
          zIndex: 9,
          [theme.breakpoints.down("lg")]: {
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 0,
          },
        }}
      >
        <CircleImage
          image={image}
          name={name}
          role="team"
          width={170}
          variant="team"
        />
      </Stack>
      <Stack
        gap={2}
        alignItems="flex-start"
        sx={{
          background: theme.palette.colors.teamCard.background,
          borderRadius: 4,
          padding: 3,
          flexGrow: 1,
          [theme.breakpoints.down("lg")]: {
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 12,
          },
        }}
      >
        <NamePlate
          style={{
            maxWidth: "55%",
            [theme.breakpoints.down("lg")]: {
              maxWidth: "100%",
              alignItems: "center",
              marginTop: 1,
              gap: 0.25,
            },
          }}
          name={name}
          role={role}
        />
        <Stack gap={2} sx={{ width: "100%", flexGrow: 1 }}>
          <Bio bio={bio} />
          <Socials socials={socials} />
        </Stack>
      </Stack>
    </Wrapper>
  );
};

const Socials = ({ socials }) => {
  const theme = useTheme();
  const iconMapping = {
    twitter: <TwitterX />,
    linkedIn: <FaLinkedinIn />,
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        position: "relative",
        marginTop: "auto",
        zIndex: 10,
        [theme.breakpoints.down("lg")]: {
          justifyContent: "flex-start",
        },
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
      }}
    >
      {socials.map((social, i) => (
        <IconButton
          color="white"
          href={social.url}
          target="_blank"
          key={`SOCIAL_${i}`}
        >
          {iconMapping[social.icon]}
        </IconButton>
      ))}
    </Stack>
  );
};

export default TeamMember;
