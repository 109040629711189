import {
  IconButton,
  useTheme,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  FaLinkedinIn,
  FaYoutube,
  FaTwitch,
  FaGithub,
  FaInstagram,
  FaQuoteLeft,
  FaQuoteRight,
} from "react-icons/fa";
import TwitterX from "components/Social/svgIcons/TwitterX";
import Text from "components/Typography/Text";
import Name from "../atoms/Name";
import Role from "../atoms/Role";
import CircleImage from "../atoms/CircleImage";
import styled from "@emotion/styled";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => !["isMobile"].includes(prop),
})(({ theme, isMobile }) => ({
  position: "relative",
  background: theme.palette.colors.teamCard.background,
  borderRadius: "16px",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxWidth: 500,
  padding: isMobile ? theme.spacing(3) : theme.spacing(4),
  marginTop: isMobile ? 0 : "88px",
  alignItems: isMobile ? "center" : "flex-start",
}));

const PersonPanel = ({ person }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:700px)");

  if (person == null) return null;

  const { name, image, specialty, bio, quote, socials, role } = person;

  const iconMapping = {
    twitter: <TwitterX />,
    linkedIn: <FaLinkedinIn />,
    youtube: <FaYoutube />,
    github: <FaGithub />,
    twitch: <FaTwitch />,
    instagram: <FaInstagram />,
  };
  return (
    <Wrapper isMobile={isMobile}>
      {isMobile ? null : (
        <CircleImage
          image={image}
          role={role}
          name={name}
          width={150}
          style={{ position: "absolute", top: "-88px", right: "20px" }}
          variant="advisor"
        />
      )}

      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "flex-end",
        }}
      >
        {isMobile ? (
          <CircleImage
            image={image}
            role={role}
            name={name}
            width={150}
            variant="advisor"
          />
        ) : null}

        <Stack
          gap={0.5}
          sx={{
            alignItems: isMobile ? "center" : "flex-start",
            justifyContent: "flex-start",
            maxWidth: isMobile ? "100%" : "60%",
          }}
        >
          <Name name={name} textAlign="left" fontSize={24} />
          <Role role={bio} textAlign={isMobile ? "center" : "left"} />
        </Stack>
      </Stack>

      <Stack>
        <Text
          style={{
            fontSize: 14,
            color: "#ffffff",
            lineHeight: 1.45,
            svg: {
              height: 12,
            },
          }}
        >
          <FaQuoteLeft /> {quote} <FaQuoteRight />
        </Text>
      </Stack>

      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        sx={{
          flexDirection: "row",
          marginTop: "auto",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            gap: 1,
          },
        }}
      >
        <Stack direction="row" gap={2}>
          {(socials || []).map((social, i) => (
            <IconButton
              color="white"
              href={social.url}
              target="_blank"
              key={`SOCIAL_${i}`}
            >
              {iconMapping[social.icon]}
            </IconButton>
          ))}
        </Stack>
        {specialty != null && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              background: theme.palette.colors.gradient.horizontal,
              borderRadius: 5,
              padding: "4px 15px",
              alignItems: "center",
            }}
            color="#ffff"
          >
            <Typography
              sx={{ textShadow: "1px 1px 10px #132133" }}
              fontSize={14}
              variant="span"
              fontWeight="bold"
              component="span"
            >
              {specialty ? specialty : ""}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Wrapper>
  );
};

export default PersonPanel;
