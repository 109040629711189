import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import Text from "components/Typography/Text";
import Title from "components/Typography/Title";
import { fontSizes } from "theme/fontSizes";
import { ABOUT_BREAKPOINT } from "..";
import Image from "components/Image";
import { colors } from "theme/colors";

const CompanyValue = ({ companyValue }) => {
  const theme = useTheme();
  const fullView = useMediaQuery(`(min-width:${ABOUT_BREAKPOINT}px)`);
  const {
    image,
    backgroundPosition,
    text,
    title,
    width,
    padding,
    marginRight,
    marginLeft,
  } = companyValue;

  return (
    <Stack alignItems="center">
      <Stack
        sx={{
          marginLeft: marginLeft ?? 0,
          padding,
          width,
          backgroundImage: `url(${image})`,
          backgroundSize: "contain",
          backgroundPosition: backgroundPosition,
          backgroundRepeat: "no-repeat",
          alignItems: "center",
          justifyContent: "center",
          [`@media(max-width:${ABOUT_BREAKPOINT}px)`]: {
            marginLeft: 0,
            width: "60%",
            padding: 0,
            backgroundImage: "none",
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        }}
      >
        <Stack
          id="molecule-text"
          alignItems="flex-start"
          gap={1}
          marginRight={marginRight ?? 0}
          sx={{
            [`@media(max-width:${ABOUT_BREAKPOINT}px)`]: {
              marginRight: 0,
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          <Title variant="h2" fontSize={fontSizes[26]}>
            {title}
          </Title>
          <Text
            variant="body2"
            lineHeight={1.6}
            fontSize={fontSizes[15]}
            color={colors.typography.muted}
          >
            {text}
          </Text>
          {!fullView && <Image src={image} width="80%" />}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CompanyValue;
