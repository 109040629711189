import React from "react";
import { Box, useTheme, Container, Stack } from "@mui/material";
import Navigation from "components/Navigation/Navigation";
import Footer from "components/Footer/Footer";
import PageSEO from "components/Utility/PageSEO";
import { seo } from "data/seo";
import { TRIBALLY_TITLE } from "config";
import { SEO_DESCRIPTION } from "config";
import { colors } from "theme/colors";

const Page = ({ children, seoPage, fullWidth, zeroPadding }) => {
  const theme = useTheme();
  return (
    <>
      <PageSEO
        title={seoPage in seo ? seo[seoPage].title : TRIBALLY_TITLE}
        description={
          seoPage in seo ? seo[seoPage].description : SEO_DESCRIPTION
        }
      />
      <Box
        sx={{
          background: colors.background,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Stack gap={20} sx={{ position: "relative", zIndex: 2 }}>
          <Navigation />

          <Container
            id="container"
            maxWidth={fullWidth ? false : "xl"}
            sx={{
              pb: 30,
              paddingLeft: zeroPadding ? "0px!important" : "24px",
              paddingRight: zeroPadding ? "0px!important" : "24px",
              minHeight: "100vh",
              [theme.breakpoints.down("sm")]: {
                pt: 0,
                pb: 20,
              },
            }}
          >
            <Stack
              gap={20}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  gap: 10,
                },
              }}
            >
              {children}
            </Stack>
          </Container>
        </Stack>

        <Footer />
      </Box>
    </>
  );
};

export default Page;
