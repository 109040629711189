export const companyValues = {
  growth: {
    image: "images/about-us/growth.png",
    backgroundPosition: "bottom",
    title: "Seek continuous & incremental improvement",
    text: "At Tribally, we are driven, passionate and self-motivated. We believe in constantly improving ourselves and our work, and we are not afraid to take on new challenges. We strive for excellence in everything we do, and we are always looking for ways to do better.",
    padding: "0px 0 192px 307px",
    paddingMedium: `0 0 180px 244px`,
    width: 637,
    marginRight: "-40px",
  },
  thinking: {
    image: "images/about-us/long-term.png",
    backgroundPosition: "bottom",
    title: "Think long term",
    text: "As entrepreneurial self-starters, we understand the importance of thinking long term. We are proactive in our approach, always looking ahead to anticipate and prepare for the future. We are committed to building a sustainable and successful business that will stand the test of time.",
    paddingMedium: `0 45px 276px 0`,
    padding: "0px 45px 256px 0px",
    mobilePadding: `0`,
    width: 400,
  },
  communication: {
    image: "images/about-us/communication.png",
    backgroundPosition: "bottom",
    title: "Communicate openly & honestly",
    text: "Communication is key at Tribally, and we pride ourselves on being open and honest in all of our interactions. We believe in transparency and integrity, and we foster a culture of trust and respect. We value feedback and collaboration, and we are always willing to listen and learn from each other.",
    padding: `0 183px 276px 0`,
    width: 520,
    marginLeft: 4,
  },
  quality: {
    image: "images/about-us/quality.png",
    backgroundPosition: "bottom",
    title: "Get the right things done well",
    text: "We are energetic problem solvers who are committed to getting the right things done well. We are results-driven and focused on achieving our goals. We are not afraid to take on challenges and we are always looking for ways to improve our performance. We work hard and we are dedicated to delivering high-quality work that meets the needs of our partners, whilst executing our vision.",
    paddingMedium: `0 0 290px 20px`,
    padding: `0 0 285px 20px`,
    width: 480,
    marginLeft: -40,
  },
};
