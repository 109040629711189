import React from "react";
import Divider from "components/Divider";
import { Stack, useTheme } from "@mui/material";
import Title from "components/Typography/Title";
import { fontSizes } from "theme/fontSizes";

const DividerWithLabel = ({ label }) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      gap={6}
      width="50%"
      sx={{ [theme.breakpoints.down("sm")]: { width: "90%" } }}
    >
      <Divider />
      <Title textAlign="center" fontSize={fontSizes[32]}>
        {label}
      </Title>
    </Stack>
  );
};

export default DividerWithLabel;
