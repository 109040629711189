import React, { useState, useEffect } from "react";
import {
  Container,
  Toolbar,
  IconButton,
  Drawer,
  AppBar,
  List,
  ListItem,
  ListItemText,
  Stack,
  Link,
} from "@mui/material";
import { CgMenuGridR } from "react-icons/cg";
import { useTheme } from "@mui/system";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { navLinks } from "components/Navigation/Utils/data";
import { ImCross } from "react-icons/im";
import GoHomeButton from "./GoHomeButton";

const Navigation = ({ variant }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 880
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element)
        element.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [hash]);

  const getLinks = () => {
    return (
      <List
        sx={{
          display: "flex",
          flexDirection: mobileView ? "column" : "row",
          gap: 3,
          "& .MuiListItem-root": {
            color: "#fff",
            position: "relative",
            textAlign: mobileView ? "left" : "center",
            px: 1,
            transition: "0.2s color ease",
            textShadow:
              variant === "partnerships" && !mobileView
                ? "0 0 12px #1b4767"
                : "none",
            "&.Mui-selected": {
              background: "transparent",
              ".MuiListItemText-root": {
                color:
                  variant === "partnerships" && !mobileView
                    ? theme.palette.colors.black
                    : theme.palette.colors.primary,
                textShadow: "none",
              },
              "&:hover": {
                background: "transparent",
              },
            },
            "&:hover": {
              color:
                variant === "partnerships" && !mobileView
                  ? theme.palette.colors.black
                  : theme.palette.colors.primary,
              textShadow: variant === "partnerships" ? "0 0 4px #fff" : "none",
              background: "transparent",
            },
            "& .MuiListItemText-root": {
              my: 0,
            },
          },
        }}
      >
        {navLinks.map(({ text, to, hashTo, href }) => {
          const destination = to || hashTo || href;
          return (
            <ListItem
              button
              component={
                hashTo != null ? HashLink : to != null ? RouterLink : Link
              }
              to={destination}
              href={destination}
              selected={
                destination === pathname ||
                (destination !== "/" &&
                  pathname.split("/").includes(destination.replace("/", "")))
              }
              target={href != null ? "_blank" : "_self"}
              key={`NAV_ITEM_${destination}`}
              disableGutters
              disableRipple
            >
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  fontWeight: 600,
                  variant: "body2",
                  fontSize: 15,
                  color: "inherit",
                  whiteSpace: "nowrap",
                }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const displayDesktop = (variant) => {
    return (
      <Container
        sx={{
          pt: 3,
          [theme.breakpoints.down("sm")]: {
            pt: 1,
          },
        }}
      >
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          {variant === "ronin" ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
              gap={3}
            >
              <Logo style={{ maxWidth: 100 }} />
              <ImCross color="#fff" />
              <RoninLogo style={{ maxWidth: 100 }} />
            </Stack>
          ) : (
            <Logo />
          )}

          <Stack direction="row" gap={2} alignItems="center">
            {getLinks()}
            <GoHomeButton />
          </Stack>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar sx={{ justifyContent: "space-between", py: 1 }}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          sx={{ "& .MuiPaper-root": { py: 6, px: 2 } }}
        >
          <Stack gap={4}>
            <Logo />

            {getLinks()}
          </Stack>
        </Drawer>
        {variant === "ronin" ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="80%"
          >
            <Logo style={{ maxWidth: 100 }} />
            <ImCross color="#fff" />
            <RoninLogo style={{ maxWidth: 100 }} />
          </Stack>
        ) : (
          <Logo />
        )}

        <IconButton
          {...{
            edge: "start",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
          sx={{
            color:
              variant === "partnerships" ? theme.palette.colors.black : "#fff",
            fontSize: 40,
          }}
        >
          <CgMenuGridR />
        </IconButton>
      </Toolbar>
    );
  };

  return (
    <Container sx={{ pt: 1 }} disableGutters>
      <AppBar position="static">
        {mobileView ? displayMobile() : displayDesktop(variant)}
      </AppBar>
    </Container>
  );
};

const Logo = ({ style }) => (
  <Link
    to="https://tribally.games/"
    component={RouterLink}
    sx={{
      width: 140,
      ...style,
      img: {
        width: "100%",
      },
      "&:hover": {
        transform: "scale(1.1)",
      },
    }}
  >
    <img src="/images/tribally-logo.png" alt="Tribally Logo" />
  </Link>
);

const RoninLogo = ({ style }) => (
  <Link
    href="https://wallet.roninchain.com/"
    target="_blank"
    sx={{
      width: 140,
      ...style,
      img: { width: "100%" },
      "&:hover": {
        transform: "scale(1.1)",
      },
    }}
  >
    <img
      src="/images/partners/AdditionalLogos/ronin-horizontal.png"
      alt="Ronin Logo"
    />
  </Link>
);

export default Navigation;
