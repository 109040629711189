import { Stack } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/system";
import FooterSocials from "components/Footer/molecules/FooterSocials";
import FooterLinks from "components/Footer/molecules/FooterLinks";
import Logo from "components/Footer/atoms/Logo";
import SiteInfo from "./molecules/SiteInfo";

const Footer = () => {
  const theme = useTheme();
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={5}
      sx={{
        background: theme.palette.colors.black,
        pt: 4,
        pb: 4,
        [theme.breakpoints.down("lg")]: {
          px: 2,
        },
      }}
    >
      <Stack
        width="70%"
        direction="row"
        alignItems="space-between"
        justifyContent="center"
        sx={{
          flexDirection: "row",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            gap: 2,
          },
        }}
      >
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          gap={2}
          sx={{
            [theme.breakpoints.down("lg")]: {
              justifyContent: "center",
            },
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              gap: 1,
            },
          }}
        >
          <Logo width={130} />
          <FooterSocials gap={0} />
        </Stack>
        <FooterLinks />
      </Stack>
      <SiteInfo />
    </Stack>
  );
};

export default Footer;
