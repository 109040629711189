import { colors } from "theme/colors";

export const drawer = {
  MuiDrawer: {
    styleOverrides: {
      paper: {
        background: colors.black800,
      },
    },
  },
};
