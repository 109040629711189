import { fonts } from "theme/fonts";
import { colors } from "theme/colors";
import { fontSizes } from "theme/fontSizes";

export const typography = {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: fontSizes[48],
        lineHeight: 50 / 48,
        color: colors.typography.main,
        fontFamily: fonts.header,
      },
      h2: {
        fontSize: fontSizes[30],
        lineHeight: 38 / 32,
        color: colors.typography.main,
        fontFamily: fonts.header,
      },
      h3: {
        fontSize: fontSizes[26],
        lineHeight: 30 / 26,
        color: colors.typography.main,
        fontFamily: fonts.header,
      },
      h4: {
        fontSize: fontSizes[20],
        lineHeight: 24 / 20,
        color: colors.typography.main,
        fontFamily: fonts.header,
      },
      body1: {
        fontSize: fontSizes[18],
        lineHeight: 26 / 18,
        fontWeight: 400,
        color: colors.typography.main,
      },
      body2: {
        fontSize: fontSizes[16],
        lineHeight: 24 / 16,
        fontWeight: 400,
        color: colors.typography.main,
      },
      body3: {
        lineHeight: fontSizes[14],
        fontSize: 20 / 14,
        fontWeight: 400,
        color: colors.typography.main,
      },
      span: {
        fontSize: fontSizes[14],
        lineHeight: 20 / 14,
        fontWeight: 500,
        color: colors.typography.main,
        fontFamily: fonts.body,
      },
    },
  },
};
