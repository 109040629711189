import { Stack, useTheme } from "@mui/material";
import Founders from "components/Team/organisms/Founders";
import Advisors from "components/Team/organisms/Advisors";
import Creators from "components/Team/organisms/Creators";
import Employees from "components/Team/organisms/Employees";
import styled from "@emotion/styled";
import MainHeader from "components/Typography/MainHeader";
import { TRIBALLY_TEAM_DESCRIPTION } from "config";
import TeamLogos from "components/TeamLogos/TeamLogos";
import { teamLogos, advisorLogos } from "components/TeamLogos/Utils/logosUtils";
import DividerWithLabel from "components/Layout/DividerWithLabel";

const Content = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(20),
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    gap: theme.spacing(8),
  },
}));

const Team = () => {
  const theme = useTheme();
  return (
    <Stack gap={8}>
      <MainHeader header="Team" text={TRIBALLY_TEAM_DESCRIPTION} />

      <Content>
        <Stack alignItems="center" gap={8}>
          <DividerWithLabel label="Tribally Founders" />
          <Founders />
          <TeamLogos
            title="Tribally founders have worked with:"
            logos={teamLogos}
          />
        </Stack>

        <Stack alignItems="center" justifyContent="center" gap={8}>
          <DividerWithLabel label="Tribally Core Team" />
          <Employees />
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          gap={8}
          sx={{
            width: "80%",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              paddingBottom: 12,
            },
          }}
        >
          <DividerWithLabel label="Founding Creators" />
          <Creators />
        </Stack>

        <Stack
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 6, sm: 6, md: 8 }}
        >
          <DividerWithLabel label="Our Advisors" />
          <Advisors />
          <TeamLogos title="" logos={advisorLogos} />
        </Stack>
      </Content>
    </Stack>
  );
};

export default Team;
