import { Stack, Typography } from "@mui/material";
import CustomLink from "components/CustomLink";
import React from "react";

const Logo = ({ src, alt, title, href, height, width, desaturate = true }) => (
  <CustomLink href={href}>
    <Stack
      className="logo"
      alignItems="center"
      sx={{
        filter: desaturate ? "grayscale(90%)" : "none",
        transition: "0.2s transform ease",
        "& img": {
          height,
          width,
        },
        "&:hover": {
          filter: desaturate ? "grayscale(0%)" : "none",
          transform: "scale(1.1)",
        },
      }}
    >
      <img src={src} alt={alt} />

      <Typography
        variant="span"
        sx={{ visibility: "hidden", height: 0, width: 0 }}
      >
        {title}
      </Typography>
    </Stack>
  </CustomLink>
);

export default Logo;
