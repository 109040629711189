import React from "react";
import { Typography } from "@mui/material";

const Text = ({
  children,
  textAlign = "left",
  style = {},
  color = "inherit",
  fontWeight = 500,
  fontSize = "intial",
  lineHeight,
  variant = "body2",
}) => {
  return (
    <Typography
      sx={style}
      fontSize={fontSize}
      variant={variant}
      textAlign={textAlign}
      color={color}
      fontWeight={fontWeight}
      lineHeight={lineHeight ? lineHeight : "initial"}
    >
      {children}
    </Typography>
  );
};

export default Text;
