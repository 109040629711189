import React from "react";
import { Stack, useTheme } from "@mui/material";
import Title from "components/Typography/Title";
import MainHeader from "components/Typography/MainHeader";
import { fontSizes } from "theme/fontSizes";
import TeamMember from "components/Team/molecules/TeamMember";
import Principle from "pages/About/molecules/Principle";
import { communityPrinciples } from "pages/About/data/communityPrinciples";
import SocialCTA from "components/CTA/SocialCTA";
import { communityLeads } from "pages/About/data/creatorPanels";

const SocialValue = () => {
  const theme = useTheme();

  return (
    <Stack
      id="social-value-container"
      alignItems="center"
      justifyContent="center"
      paddingLeft={2}
      paddingRight={2}
      paddingTop={15}
      marginTop={5}
      gap={8}
      sx={{
        backgroundImage: `url(/images/backgrounds/triangle.png)`,
        backgroundPosition: "topcenter",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("sm")]: { paddingTop: 5, marginTop: 0 },
        [theme.breakpoints.down("sm")]: { gap: 4 },
      }}
    >
      <Stack width="100%" alignItems="center" justifyContent="center" gap={4}>
        <img
          src="/images/about-us/tribally-community-decoration.png"
          alt="Tribally Community"
          width="250px"
        />
        <MainHeader
          header="Welcome To The Tribally Community"
          text="The core community principles at Tribally stem from the belief that we prioritise the human aspect of our members as we unite in our love of gaming experiences."
          headerVariant="h2"
          headerFontSize={fontSizes[32]}
          maxWidth={800}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        gap={8}
        sx={{
          maxWidth: "800px",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            maxWidth: 600,
          },
          [theme.breakpoints.down("sm")]: { gap: 1, maxWidth: 400 },
        }}
      >
        <TeamMember variant="community" person={communityLeads.tq} />
      </Stack>

      <SocialCTA
        title="Join the Tribally Community"
        text="Connect with our socials to take part in our Community Events & Giveaways"
      />

      <Stack
        position="relative"
        alignItems="center"
        justifyContent="center"
        paddingTop={10}
        gap={2}
      >
        <img
          src="/images/about-us/heart-handshake.png"
          alt="Community Principles"
          width="100px"
        />
        <Title
          style={{
            fontSize: "1.8rem",
            textAlign: "center",
            [theme.breakpoints.down("sm")]: {
              fontSize: "1.5em",
            },
          }}
        >
          Our Community principles
        </Title>
      </Stack>

      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        gap={2}
        sx={{
          flexDirection: "row",
          [theme.breakpoints.down("lg")]: { flexDirection: "column" },
        }}
      >
        {communityPrinciples.map((item, i) => (
          <Principle
            key={i}
            title={item.title}
            text={item.text}
            icon={item.icon}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default SocialValue;
