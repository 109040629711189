import { Stack } from "@mui/material";
import React from "react";
import CustomLink from "components/CustomLink";

const Logo = (width) => {
  return (
    <Stack width={width}>
      <CustomLink to="https://tribally.games/">
        <img src="/images/tribally-logo.png" alt="Tribally" width="100%" />
      </CustomLink>
    </Stack>
  );
};

export default Logo;
