import { Stack, useTheme } from "@mui/material";
import { team } from "../data/teamData";
import TeamMember from "../molecules/TeamMember";

const Founders = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        maxWidth: 1000,
        margin: "0 auto",
        gap: 4,
        [theme.breakpoints.down("xl")]: { width: "100%" },
      }}
    >
      <TeamMember variant="team" person={team.best} />
      <TeamMember variant="team" person={team.amy} />
      <TeamMember variant="team" person={team.rare} />
      <TeamMember variant="team" person={team.ged} />
    </Stack>
  );
};

export default Founders;
