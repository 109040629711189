export const creators = {
  cloudwhite: {
    name: "cloudwhite",
    role: `Web3 investor & owner Big3 Trilogy`,
    image: "cloudwhite.png",
    socials: [{ icon: "twitter", url: "https://twitter.com/cloudwhiteNFT" }],
    audienceNumber: "59k",
  },
  chief: {
    name: "Chief",
    role: `Co-Founder of Esports4Everyone`,
    image: "chief.png",
    socials: [{ icon: "twitter", url: "https://twitter.com/Chief_AxieGG" }],
    audienceNumber: "12k",
  },
  artic: {
    name: "Artic",
    role: `Founder of Across Lunacia`,
    image: "artic.png",
    socials: [{ icon: "twitter", url: "https://twitter.com/Axie44" }],
    audienceNumber: "32k",
  },
  wiceTroc1ty: {
    name: "wiceTroc1ty",
    role: `Axie Creator & Ronin Validator`,
    image: "wicetroc1ty.png",
    socials: [{ icon: "twitter", url: "https://twitter.com/wiceTroc1ty" }],
    audienceNumber: "4k",
  },
  stark: {
    name: "Stark",
    role: "Web3 Creator & Community Builder",
    image: "stark.png",
    socials: [{ icon: "twitter", url: "https://twitter.com/Stark_axie" }],
    audienceNumber: "49k",
  },
  creatoroftime: {
    name: "creatoroftime",
    role: `Founder of Lunacian Times`,
    image: "creatoroftime.png",
    socials: [{ icon: "twitter", url: "https://twitter.com/CausalityOfLife" }],
    audienceNumber: "11k",
  },
  zimmahtv: {
    name: "zimmmahTV",
    role: `Specialist at Sky Mavis (edited)`,
    image: "zimmahtv.png",
    socials: [{ icon: "twitter", url: "https://twitter.com/zimmah" }],
    audienceNumber: "1k",
  },
  hidden: {
    name: "",
    role: "",
    image: "hidden.png",
    socials: [],
    audienceNumber: "17k",
  },
};
