import React from "react";
import { TRIBALLY_COMPANY_NAME } from "config";
import Text from "components/Typography/Text";

const Copyright = () => {
  const year = new Date().getFullYear();

  return (
    <Text variant="span" fontWeight="600" fontSize={12} color="bright">
      © {year} {TRIBALLY_COMPANY_NAME}
    </Text>
  );
};

export default Copyright;
