import { colors } from "theme/colors";

export const link = {
  MuiLink: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        color: colors.primary,
        textDecoration: "none",
        transition: "0.2s all ease",
        display: "inline-block",
      },
    },
  },
};
