import { Stack } from "@mui/material";
import Role from "./Role";
import styled from "@emotion/styled";
import Name from "./Name";

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
  alignItems: "flex-start",
  justifyContent: "flex-end",
}));

const NamePlate = ({ name, role, textAlign, style }) => {
  return (
    <Wrapper sx={{ ...style }}>
      <Name name={name} textAlign={textAlign ? textAlign : "left"} />
      <Role role={role} textAlign={textAlign ? textAlign : "left"} />
    </Wrapper>
  );
};

export default NamePlate;
