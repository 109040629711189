import { Typography } from "@mui/material";

const Role = ({ role, textAlign = "left", fontSize }) => {
  return (
    <Typography
      className="role"
      fontSize={fontSize ? fontSize : 12}
      fontWeight={700}
      letterSpacing="0.5px"
      textAlign={textAlign}
      sx={{ color: (theme) => theme.palette.colors.black300 }}
    >
      {role}
    </Typography>
  );
};

export default Role;
