import React from "react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { companyValues } from "../data/companyValues";
import CompanyValue from "../molecules/CompanyValue";
import { ABOUT_BREAKPOINT } from "..";

const Wrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(12),
  justifyContent: "center",
  paddingLeft: "24px",
  paddingRight: "24px",

  "& .column": {
    "&:nth-of-type(2)": { marginTop: 100 },
  },

  [`@media(max-width:${ABOUT_BREAKPOINT}px)`]: {
    flexDirection: "column",
    gap: theme.spacing(8),
    "& .column": {
      "&:nth-of-type(2)": { marginTop: 0 },
    },
  },
}));

const Column = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(8),
}));

const CompanyValues = () => {
  return (
    <Wrapper>
      <Column className="column">
        <CompanyValue companyValue={companyValues.growth} />
        <CompanyValue companyValue={companyValues.thinking} />
      </Column>

      <Column className="column">
        <CompanyValue companyValue={companyValues.communication} />
        <CompanyValue companyValue={companyValues.quality} />
      </Column>
    </Wrapper>
  );
};

export default CompanyValues;
