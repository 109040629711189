import React from "react";
import { Routes, Route } from "react-router";
import Team from "pages/Team";
import About from "pages/About";
import Page from "components/Layout/Page";
import Invest from "pages/Invest";

const CustomRoutes = () => (
  <Routes>
    <Route
      exact
      path="/team"
      element={
        <Page seoPage="team" fullWidth={true}>
          <Team />
        </Page>
      }
    />
    <Route
      exact
      path="/"
      element={
        <Page seoPage="about" fullWidth={true} zeroPadding={true}>
          <About />
        </Page>
      }
    />
    <Route
      exact
      path="/invest"
      element={
        <Page seoPage="invest" fullWidth={true} zeroPadding={true}>
          <Invest />
        </Page>
      }
    />
    {/* <Route
      exact
      path="/partnerships"
      element={
        <PartnershipsPage seoPage="partnerships" fullWidth={true}>
          <GamePartners />
        </PartnershipsPage>
      }
    /> */}
  </Routes>
);

export default CustomRoutes;
