export const chip = {
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        userSelect: "none",
      },
      label: {
        lineHeight: 1.2,
      },
    },
  },
};
