import { Stack, useTheme } from "@mui/material";
import { advisors } from "../data/advisorData";
import PersonPanel from "../molecules/PersonPanel";

const Advisors = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        display: "grid",
        justifyContent: "center",
        gridTemplateColumns: "1fr 1fr",
        gap: 4,
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
      }}
    >
      <PersonPanel person={advisors.aleksander} />
      <PersonPanel person={advisors.ramesh} />
      <PersonPanel person={advisors.steve} />
      <PersonPanel person={advisors.james} />
      <PersonPanel person={advisors.felix} />
      <PersonPanel person={advisors.dope} />
      <PersonPanel person={advisors.lima} />
      <PersonPanel person={advisors.tq} />
      <PersonPanel person={advisors.efensie} />
      <PersonPanel person={advisors.quinn} />
    </Stack>
  );
};

export default Advisors;
