import React from "react";
import { Typography } from "@mui/material";

const Title = ({
  children,
  color = "#fff",
  textAlign,
  style,
  fontSize,
  variant = "h1",
  className,
  breakpoint,
}) => {
  return (
    <Typography
      className={className}
      variant={variant}
      color={color}
      textAlign={textAlign}
      fontSize={breakpoint ? "1.45rem" : fontSize}
      sx={{ ...style }}
    >
      {children}
    </Typography>
  );
};

export default Title;
