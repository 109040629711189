import React from "react";
import Title from "components/Typography/Title";
import SectionHeader from "components/Layout/SectionHeader";
import Text from "./Text";
import CustomLink from "components/CustomLink";
import { TRIBALLY_URL } from "config";

const MainHeader = ({
  header,
  headerVariant = "h1",
  textVariant = "body1",
  headerFontSize,
  text,
  maxWidth = 620,
  style,
  titleColor,
  textColor,
  logo = false,
}) => {
  return (
    <SectionHeader style={style} maxWidth={maxWidth}>
      {logo ? (
        <CustomLink to={TRIBALLY_URL}>
          <img
            width={80}
            src="/images/tribally-logo-mark-gradient.png"
            alt="Tribally Logo"
          />
        </CustomLink>
      ) : null}

      <Title
        color={titleColor}
        variant={headerVariant}
        fontSize={headerFontSize}
        textAlign="center"
      >
        {header ? header : null}
      </Title>
      <Text
        color={textColor}
        variant={textVariant}
        textAlign="center"
        style={{ textShadow: "0 0 10px rgba(0,0,0,0.2)", lineHeight: 1.5 }}
      >
        {text ? text : null}
      </Text>
    </SectionHeader>
  );
};

export default MainHeader;
